import { TbError404 } from "react-icons/tb";

export default function Error404Page() {
  document.title = "صفحه مورد نظر پیدا نشد - گیم کیو";
  return (
    <section class="flex items-center h-full bg-gray-900 text-gray-100">
      <div class="container flex flex-col items-center justify-center mx-auto text-center sm:max-w-md">
        <TbError404 size="20em"/>
      </div>
    </section>
  );
}
