import DynamicPage from "./pages/DynamicPage";
import Error404Page from "./pages/Error404Page";
import HomePage from "./pages/HomePage";

const routers = [
  {
    path: "/",
    element: <HomePage />,
  },
  { path: ":path", element: <DynamicPage /> },
  { path: "404", element: <Error404Page /> },
  { path: "*", element: <Error404Page /> },
];

export default routers;
