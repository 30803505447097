import { useParams } from "react-router-dom";
import ReqRouters from "../ReqRouters.json";
import { SiAparat } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { motion } from "framer-motion";

export default function DynamicPage() {
  let { path } = useParams();

  const findPath = ReqRouters.filter((item) => {
    return item.path === path;
  });

  if (!findPath[0]) {
    return window.location.replace(404);
  }
  if (findPath[0].isDirect) {
    return window.location.replace(findPath[0].link[0]);
  }
  document.title = findPath[0].title;

  return (
    <>
      <p className="text-center">انتخاب کنید</p>
      <br />
      <div className="flex space-x-4 w-10/12">
        <a href={findPath[0].link[0]}>
          <motion.div
            whileHover={{ scale: 1.2 }}
            whileTap={{
              scale: 0.8,
            }}
            className="bg-gray-900 text-center pt-3 pb-2 px-8 rounded-xl flex flex-col justify-center bg-gradient-to-r hover:from-[#FA5252] hover:to-[#DD2476]"
          >
            <SiAparat size="2.8em" className="mt-1 mb-2" />
            <p>آپارات</p>
          </motion.div>
        </a>
        <a href={findPath[0].link[1]}>
          <motion.div
            whileHover={{ scale: 1.2 }}
            whileTap={{
              scale: 0.8,
            }}
            className="bg-gray-900 text-center py-3 px-7 rounded-xl flex flex-col justify-center bg-gradient-to-r hover:from-[#FA5252] hover:to-[#DD2476]"
          >
            <BsYoutube size="3.3em" />
            <p>یوتیوب</p>
          </motion.div>
        </a>
      </div>
    </>
  );
}
